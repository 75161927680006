<template>
  <v-card class="pa-4" flat>
    <v-tabs v-model="tab" center-active @change="tabValue">
      <v-tab>
        {{ $t("master_data.student.tab1_title") }}
      </v-tab>
      <v-tab>
        {{ $t("routes.teacher") }}
      </v-tab>
      <v-tab>
        {{ $t("routes.staff") }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <Student :init="$route.query.tab == 0" />
      </v-tab-item>
      <v-tab-item>
        <StaffTeacher
          :init="$route.query.tab == 1"
          :label="$t('routes.teacher')"
          :filter="{
            'employees.type': ['TEACHER']
          }"
        />
      </v-tab-item>
      <v-tab-item>
        <StaffTeacher
          :init="$route.query.tab == 1"
          :label="$t('routes.staff')"
          :filter="{
            'employees.type': ['STAFF']
          }"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import i18n from "@/i18n";

export default {
  metaInfo: {
    title: i18n.t("routes.personalization"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Student: () => import("./Student"),
    StaffTeacher: () => import("./StaffTeacher")
  },
  data() {
    return {
      tab: this.$route.query.tab | 0,
      filterAll: []
    };
  },
  watch: {
    "$route.query.tab"(n) {
      if (n) this.tab = this.$route.query.tab;
    }
  },
  methods: {
    tabValue(tab) {
      this.$router.push({ name: "Personalization", query: { tab: tab } });
    }
  }
};
</script>
